import React, { useContext } from 'react'

import { Routes, Route } from 'react-router-dom'
import { ExhibitionProvider } from './ExhibitionProvider'
import Chapter from './Chapter/Chapter'
import Chapters from './Chapters/Chapters'
import AudioGuideTypes from './AudioGuideTypes/AudioGuideTypes'
import NextChapter from './NextChapter/NextChapter'
import NotFound from '../NotFound/NotFound'
import { ExhibitionsContext } from '../Exhibitions/ExhibitionsProvider'

const Exhibition = () => {
  const { exhibitions, loading: exhibitionsLoading } =
    useContext(ExhibitionsContext)
  if (exhibitions) {
    return (
      <ExhibitionProvider>
        <Routes>
          <Route exact path="/" element={<AudioGuideTypes />} />
          <Route exact path="/chapters/:guideType" element={<Chapters />} />
          <Route
            exact
            path="/chapters/:guideType/:chapter"
            element={<Chapter />}
          />
          <Route
            exact
            path="/chapters/:guideType/:chapter/next"
            element={<NextChapter />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ExhibitionProvider>
    )
  }
}

export default Exhibition
