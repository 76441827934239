import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './config/i18n'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ClickToComponent } from 'click-to-react-component'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <ClickToComponent editor="vscode-insiders" />
    <App />
  </>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
