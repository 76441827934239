import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import de from '../translations/de.json'
import en from '../translations/en.json'
import it from '../translations/it.json'
import hr from '../translations/hr.json'
import bs from '../translations/hr.json'
import sr from '../translations/sr.json'
import hu from '../translations/hu.json'
import tr from '../translations/tr.json'
import ar from '../translations/ar.json'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en,
      de,
      it,
      bs,
      hr,
      sr,
      hu,
      tr,
      ar,
    },
  })

export default i18n
