import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { useTranslation } from 'react-i18next'
import {
  applyTheme,
  decreaseFont,
  getFontSize,
  increaseFont,
  switchFont,
} from '../themes/utils'
import baseTheme from '../themes/base'
import darkTheme from '../themes/dark'
import purpleTheme from '../themes/purple'
import blueTheme from '../themes/blue'
import yellowPurpleTheme from '../themes/yellowPurple'
import yellowBlueTheme from '../themes/yellowBlue'

import { ReactComponent as AccessibilitySvg } from '../assets/images/accessibility-toolbar.svg'

const Header = ({ title, left }) => {
  const [barVisible, setBarVisible] = useState(false)
  const [active, setActive] = useState('')
  const [activeTheme, setActiveTheme] = useState('white_black')
  const [fontSize, setFontSize] = useState(getFontSize())

  const { t, i18n } = useTranslation()

  const toggleBar = type => {
    if (barVisible) {
      if (active === type) {
        setBarVisible(!barVisible)
      } else {
        setActive(type)
      }
    } else {
      setBarVisible(true)
      setActive(type)
    }
  }

  const lngs = {
    de: { nativeName: t('german') },
    en: { nativeName: t('english') },
    it: { nativeName: t('italian') },
    hr: { nativeName: t('croatian') },
    bs: { nativeName: t('bosnian') },
    sr: { nativeName: t('serbian') },
    hu: { nativeName: t('hungarian') },
    tr: { nativeName: t('turkish') },
    ar: { nativeName: t('arabian') },
  }

  return (
    <header
      className="sticky top-0 bg-secondary z-50"
      aria-label={t('accessibility.header')}
      role="banner"
    >
      <div className="container p-2">
        <div className="flex flex-row items-center justify-between h-12">
          {left}
          <div
            className="text-lg font-bold leading-none"
            aria-label={t('accessibility.page_title')}
          >
            {title}
          </div>
          <div className="flex flex-row items-center">
            <button
              onClick={() => toggleBar('lngs')}
              className="flex flex-row items-center mr-2"
              aria-pressed={barVisible && active === 'lngs'}
              aria-label={t('accessibility.open_lang_menu_label')}
            >
              <FontAwesomeIcon
                icon={
                  barVisible && active === 'lngs'
                    ? solid('circle-xmark')
                    : regular('globe')
                }
                size="lg"
                className="mr-1"
              />
              <div aria-label={t('accessibility.choosen_language_label')}>
                {i18n.resolvedLanguage.toUpperCase()}
              </div>
            </button>
            <button
              onClick={() => toggleBar('acc')}
              aria-pressed={barVisible && active === 'acc'}
              aria-label={t('accessibility.open_acc_menu_label')}
            >
              <AccessibilitySvg
                className="svg-inline--fa fa-2x fill-primary"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="w-full border-t-2 border-primary" />
      <div
        className={`sticky top-16 border-b-2 border-primary ${
          !barVisible ? 'hidden' : 'block'
        }`}
      >
        {active === 'lngs' && (
          <div className="container">
            {Object.keys(lngs).map((lng, index) => (
              <button
                key={lng}
                className={`
                block w-full p-2
                ${index + 1 !== Object.keys(lngs).length ? 'border-b' : ''}
                ${i18n.resolvedLanguage === lng ? 'font-bold' : ''}`}
                type="submit"
                onClick={() => {
                  i18n.changeLanguage(lng)
                  toggleBar('lngs')
                }}
                aria-label={t('accessibility.choose_lang_label', {
                  lang: lngs[lng].nativeName,
                })}
                aria-pressed={i18n.resolvedLanguage === lng}
              >
                {lngs[lng].nativeName.toUpperCase()}
              </button>
            ))}
          </div>
        )}
        {active === 'acc' && (
          <div className="container grid grid-cols-[3fr_6fr] p-2">
            <div>
              <button
                onClick={() => {
                  decreaseFont()
                  setFontSize(getFontSize())
                }}
                disabled={fontSize < 18}
                className="mr-2 disabled:opacity-50"
                aria-label={t('accessibility.decrease_font')}
              >
                <FontAwesomeIcon icon={solid('text')} size="" />-
              </button>
              <button
                onClick={() => {
                  increaseFont()
                  setFontSize(getFontSize())
                }}
                className="mr-2 disabled:opacity-50"
                disabled={fontSize > 22}
                aria-label={t('accessibility.increase_font')}
              >
                <FontAwesomeIcon icon={solid('text')} size="xl" />+
              </button>

              <button
                onClick={() => {
                  switchFont()
                }}
                className="mr-2"
                aria-label={t('accessibility.switch_font')}
              >
                <FontAwesomeIcon icon={solid('font')} size="xl" />
              </button>
            </div>
            <div className="flex flex-row  flex-wrap gap-y-1 items-center justify-end justify-self-end">
              <button
                onClick={() => {
                  applyTheme(baseTheme)
                  setActiveTheme('white_black')
                }}
                className="ml-2 border-2 border-primary"
                aria-label={t('accessibility.choose_colors', {
                  color_schema: t('accessibility.color_scheme_white_black'),
                })}
                aria-pressed={activeTheme === 'white_black'}
              >
                <div className="h-6 w-6">
                  <div className="inline-block bg-white w-1/2 h-full"></div>
                  <div className="inline-block bg-black w-1/2 h-full"></div>
                </div>
              </button>
              <button
                onClick={() => {
                  applyTheme(darkTheme)
                  setActiveTheme('black_white')
                }}
                className="ml-2 border-2 border-primary"
                aria-label={t('accessibility.choose_colors', {
                  color_schema: t('accessibility.color_scheme_black_white'),
                })}
                aria-pressed={activeTheme === 'black_white'}
              >
                <div className="h-6 w-6">
                  <div className="inline-block bg-black w-1/2 h-full"></div>
                  <div className="inline-block bg-white w-1/2 h-full"></div>
                </div>
              </button>
              <button
                onClick={() => {
                  applyTheme(purpleTheme)
                  setActiveTheme('yellow_purple')
                }}
                className="ml-2 border-2 border-primary"
                aria-label={t('accessibility.choose_colors', {
                  color_schema: t('accessibility.color_scheme_yellow_purple'),
                })}
                aria-pressed={activeTheme === 'yellow_purple'}
              >
                <div className="h-6 w-6">
                  <div className="inline-block bg-yellow w-1/2 h-full"></div>
                  <div className="inline-block bg-purple w-1/2 h-full"></div>
                </div>
              </button>
              <button
                onClick={() => {
                  applyTheme(yellowPurpleTheme)
                  setActiveTheme('purple_yellow')
                }}
                className="ml-2 border-2 border-primary"
                aria-label={t('accessibility.choose_colors', {
                  color_schema: t('accessibility.color_scheme_purple_yellow'),
                })}
                aria-pressed={activeTheme === 'purple_yellow'}
              >
                <div className="h-6 w-6">
                  <div className="inline-block bg-purple w-1/2 h-full"></div>
                  <div className="inline-block bg-yellow w-1/2 h-full"></div>
                </div>
              </button>
              <button
                onClick={() => {
                  applyTheme(blueTheme)
                  setActiveTheme('yellow_blue')
                }}
                className="ml-2 border-2 border-primary"
                aria-label={t('accessibility.choose_colors', {
                  color_schema: t('accessibility.color_scheme_yellow_blue'),
                })}
                aria-pressed={activeTheme === 'yellow_blue'}
              >
                <div className="h-6 w-6">
                  <div className="inline-block bg-yellow w-1/2 h-full"></div>
                  <div className="inline-block bg-blue w-1/2 h-full"></div>
                </div>
              </button>
              <button
                onClick={() => {
                  applyTheme(yellowBlueTheme)
                  setActiveTheme('blue_yellow')
                }}
                className="ml-2 border-2 border-primary"
                aria-label={t('accessibility.choose_colors', {
                  color_schema: t('accessibility.color_scheme_blue_yellow'),
                })}
                aria-pressed={activeTheme === 'blue_yellow'}
              >
                <div className="h-6 w-6">
                  <div className="inline-block bg-blue w-1/2 h-full"></div>
                  <div className="inline-block bg-yellow w-1/2 h-full"></div>
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
