import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player/file'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Spinner from './Spinner'

const AudioPlayer = ({
  url,
  prev,
  next,
  onEnded,
  disablePrev,
  disableNext,
}) => {
  const [playing, setPlaying] = useState(false)
  const [ready, setReady] = useState(false)
  const [currentSeek, setCurrentSeek] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)
  const playerRef = useRef()
  const { t } = useTranslation()

  const playingRef = useRef(playing)
  const setPlayingRef = data => {
    playingRef.current = data
    setPlaying(data)
  }

  const handleSpace = event => {
    if (event.code === 'Space') {
      event.preventDefault()
      if (playingRef.current) {
        handlePause()
      } else {
        handlePlay()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleSpace)
    return () => {
      document.removeEventListener('keydown', handleSpace)
    }
  }, [])

  useEffect(() => {
    const videoElement = playerRef?.current?.getInternalPlayer()
    videoElement?.load()
  }, [url])

  const handleOnReady = e => {
    setTotalDuration(playerRef.current.getDuration())
    setReady(true)
  }

  const handlePlay = () => {
    setPlayingRef(true)
    const videoElement = playerRef?.current?.getInternalPlayer()
    videoElement?.play()
  }

  const handlePause = () => {
    setPlayingRef(false)
    const videoElement = playerRef?.current?.getInternalPlayer()
    videoElement?.pause()
  }

  const handleSeekChange = e => {
    setCurrentSeek(e.target.value)
    playerRef.current.seekTo(e.target.value)
  }

  const handleProgress = e => {
    setCurrentSeek(e.playedSeconds)
  }

  return (
    <>
      <ReactPlayer
        ref={playerRef}
        url={url}
        controls={false}
        width="100%"
        height="100%"
        onProgress={handleProgress}
        onReady={handleOnReady}
        onEnded={() => {
          handlePause()
          onEnded()
        }}
      />
      <div
        className="flex flex-row mx-6 justify-between items-center mb-8"
        aria-busy={!ready}
        aria-label={t('accessibility.audio_player_controls')}
      >
        <button
          onClick={() => {
            handlePause()
            setCurrentSeek(0)
            setReady(false)
            prev()
          }}
          className="p-2 disabled:opacity-50"
          aria-label={t('accessibility.prev_chapter_label')}
          disabled={disablePrev}
        >
          <FontAwesomeIcon icon={solid('backward-step')} size="2xl" />
        </button>
        <button
          onClick={() => {
            if (playing) {
              handlePause()
            } else {
              handlePlay()
            }
          }}
          className="rounded-full p-2 border-2 w-20 h-20 flex flex-row justify-center items-center"
          aria-label={
            playing
              ? t('accessibility.pause_audioguide_label')
              : t('accessibility.play_audioguide_label')
          }
          disabled={!ready}
        >
          {ready ? (
            <FontAwesomeIcon
              icon={playing ? solid('pause') : solid('play')}
              size="2x"
            />
          ) : (
            <Spinner />
          )}
        </button>
        <button
          onClick={() => {
            handlePause()
            setCurrentSeek(0)
            setReady(false)
            next()
          }}
          className="p-2 disabled:opacity-50"
          aria-label={t('accessibility.next_chapter_label')}
          disabled={disableNext}
        >
          <FontAwesomeIcon icon={solid('forward-step')} size="2xl" />
        </button>
      </div>
      <div className="flex flex-row mx-6 justify-between items-center mb-4">
        <input
          id="currentSeek"
          type="range"
          min={0}
          aria-valuemin={0}
          max={totalDuration}
          aria-valuemax={totalDuration}
          className="slider"
          value={currentSeek}
          aria-valuenow={currentSeek}
          onChange={e => handleSeekChange(e)}
          step={1}
          disabled={!ready}
          aria-label={t('accessibility.choose_time_label')}
        />
      </div>
      <div className="flex flex-row mx-6 justify-between items-center">
        <div aria-label={t('accessibility.time_current')}>
          {new Date(currentSeek * 1000).toISOString().slice(14, 19)}
        </div>
        <div aria-label={t('accessibility.time_left')}>
          {'-' +
            new Date((totalDuration - currentSeek) * 1000)
              .toISOString()
              .slice(14, 19)}
        </div>
      </div>
    </>
  )
}

export default AudioPlayer
